import { handleErrorWithSentry } from "@sentry/sveltekit";
import { sentryDsn, sentryEnv, appVersion } from "./env";
import { init as sentryInit } from "@sentry/sveltekit";

sentryInit({
  debug: false,
  dsn: sentryDsn,
  environment: sentryEnv,
  release: appVersion,
  ignoreErrors: [
    // Offline issues
    "Failed to fetch",
    "Failed to register a ServiceWorker",
    "NetworkError when attempting to fetch resource.",
    "Importing a module script failed.",
    "Unable to preload CSS",
    "Cancelled",
    "Load failed",
    // 404s
    "Not Found",
    "Not found",
    "favicon.ico",
    // Random plugins/extensions
    "top.GLOBALS",
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage"
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /^webkit-masked-url:\/\//i
  ]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
